import { useEffect } from 'react';
import * as Colors from '@/atoms/colors';
import { CompleteOnboardingCard } from '@/organisms/cards';
import Dashboard from '@/organisms/dashboards/Dashboard';
import Navigation from '@/organisms/dashboards/Navigation';
import { FlowStyle, FlowType } from '@/shared/enums';
import { useDesignsContext } from '@/shared/providers/DesignsProvider';
import { useFlowsContext } from '@/shared/providers/FlowsProvider';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider'; import Box from '@/components/Box';
import ContextBarContainer from '@/components/ContextBarContainer';
import useContextStore from '@/components/ContextBarContainer/useContextStore';
import DashboardSkeleton from './DashboardSkeleton';

const registry = {
  DASHBOARD: Navigation,
};

const DashboardLayout = () => {
  const { isLoading } = useDesignsContext();
  const { bar, setBar } = useContextStore();
  const { scenario } = useScenarioContext();
  const { flows } = useFlowsContext();
  const onboardFlow = flows
    .get(FlowType.ROLE_WIZARD)
    ?.get(scenario?.entity?.uuid)
    ?.get(FlowStyle.ONBOARDING)?.[0];

  useEffect(() => {
    if (!bar?.current) {
      setBar({ current: 'DASHBOARD' });
    }
  }, [bar?.current]);

  return (
    <Box
      sx={{
        backgroundColor: Colors.Base.background.secondary,
        color: Colors.Base.font.primary,
        height: 'calc(100vh - 41px)',
        display: 'flex',
      }}
    >
      {isLoading ? (
        <DashboardSkeleton />
      ) : (
        <>
          <Box
            flex={1}
            height="100%"
            p={2}
            sx={{
              overflowY: 'scroll',
              scrollBehavior: 'smooth',
            }}
          >
            {onboardFlow ? (
              <Box>
                <CompleteOnboardingCard flow={onboardFlow} />
              </Box>
            ) : (
              <Dashboard />
            )}
          </Box>
          <ContextBarContainer registry={registry} />
        </>
      )}
    </Box>
  );
};

export default DashboardLayout;
