import { Avatar } from '@/atoms/avatars';
import * as Colors from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { FlowList } from '@/organisms/flows';
import { RoleList } from '@/organisms/roles';
import { TeamList } from '@/organisms/teams';
import { useUserRelatedEntities } from '@/shared/hooks';
import { avatarImage } from '@/shared/utils/profile';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import * as Auth from '@pkg/auth';

const RestrictedDashboardLayout = () => {
  const { me } = Auth.useStore();
  const { groups } = useUserRelatedEntities({ liveDesignsOnly: true });

  return (
    <Stack
      sx={{
        backgroundColor: Colors.Base.background.secondary,
        color: Colors.Base.font.primary,
        height: 'calc(100vh)',
      }}
    >
      <div
        style={{
          overflowX: 'hidden',
          overflowY: 'auto',
          scrollBehavior: 'smooth',
        }}
      >
        <Grid container direction="row" py={8} px={4} spacing={6}>
          <Grid item xs={12} md={9} mdOffset={2} lg={6} lgOffset={3}>
            <Stack mb={2}>
              <Avatar
                name={`${me.first_name} ${me.last_name}`}
                size={Size.X_LARGE}
                src={avatarImage(me)}
              />
            </Stack>
            <Heading variant="h2" overrideStyles={{ mb: 0.5 }}>
              Hello <span style={{ fontWeight: 700 }}>{me?.first_name},</span>
            </Heading>
            <Heading variant="h3">Welcome back to Beamible!</Heading>
          </Grid>
          <Grid item xs={12} md={9} mdOffset={2} lg={6} lgOffset={3}>
            <FlowList />
          </Grid>
          <Grid item xs={12} md={9} mdOffset={2} lg={6} lgOffset={3}>
            <RoleList allowLinks={!me?.is_role_only} />
          </Grid>
          {groups?.length > 0 && (
            <Grid item xs={12} md={9} mdOffset={2} lg={6} lgOffset={3}>
              <TeamList />
            </Grid>
          )}
        </Grid>
      </div>
    </Stack>
  );
};

export default RestrictedDashboardLayout;
